@import "../../Asset/theme/_var.scss";

.custom-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: $white;
  font-size: 16px;  // 1.6rem = 16px
  font-weight: 600;
  text-transform: capitalize;
  border-radius: 8px;  // 0.8rem = 8px
  padding: 10.5px 20px;  // 1.05rem = 10.5px, 2rem = 20px
  background: $primary;
  border: 1px solid $primary;  // 0.1rem = 1px
  position: relative;
  transition: all linear 0.25s;

  @media (max-width: 1679px) {
    font-size: 16px;  // 1.6rem = 16px
  }

  @media (max-width: 1679px) {
    font-size: 14px;  // 1.4rem = 14px
  }

  @media (max-width: 575px) {
    font-size: 12px;  // 1.2rem = 12px
    padding: 7.5px 15px;  // 0.75rem = 7.5px, 1.5rem = 15px
  }

  &:disabled {
    cursor: not-allowed;
    // pointer-events: none;
    background: rgba($secondary4, 0.15);
    border-color: rgba($secondary4, 0.15);
    color: $secondary4;
  }

  &:hover {
    background: transparent;
    color: $primary;
  }

  &__icon {
    margin-left: 10px;  // 1rem = 10px
    position: relative;
    top: -2px;
    right: -4px;

    svg {
      width: 24px;  // 2.4rem = 24px
    }
  }
}
