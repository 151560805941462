@import "../../Asset/theme/_var.scss";

.primary-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: $black;
  &__inner {
    flex: 1 0 auto;
    padding-top: 105px;
    padding-bottom: 20px;
    overflow-x: hidden;

    @media (max-width: 1679px) {
      padding-top: 100px;
    }

    // @media (max-width: 767px) {
    //   padding-top: 8rem;
    // }
  }
}
