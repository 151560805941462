@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import "../Fonts/stylesheet.scss";

$baseFont: "Inter", sans-serif;
$baseFont2: "Montserrat", sans-serif;

$white: #ffffff;
$black:  #000000;
$black2: #0d2234;
$black3: #000026;
$primary: #0086FF;
$light_text:#AAAAAA;
$primary2: #f5954e;
$secondary: #feb84d;
$secondary2: #000040;
$secondary3: #a3a3a3;
$secondary4: #0086FF;
$secondary5: #66668c;
$secondary6: #ccccd9;
$borderClr: rgba(255, 255, 255, 0.1);
$borderClr2: #252525;
$green: #7bbb3f;
$blue: #2797d1;
$red: #c3292d;
$orange: #f68630;
$skyblue: #01cde4;
$yellowdark: #fbbb10;
$reddark: #e54c40;
$bannner_bg: linear-gradient(88.08deg, rgba(1, 124, 201, 0.154) 3.13%, rgba(8, 147, 234, 0.35) 48.43%, rgba(1, 138, 224, 0.173509) 98.38%);
$btnGradient: linear-gradient(270deg, #fde679 -0.16%, #f26c33 86.5%);
$gradient: linear-gradient(276deg, #feb84d 16.17%, #ef7027 95.08%);
$gradient2: linear-gradient(0deg, rgba(0, 0, 0, 0) -29.92%, #161616 97.38%);
$gradient3: linear-gradient(90deg, #fde076 1.57%, rgba(242, 107, 51, 0) 101.1%);
$gradient4: linear-gradient(261.01deg, #212122 2.84%, rgba(0, 0, 0, 0) 165.67%);
$boxShadow: 0 1.2rem 1.6rem -0.4rem rgba(255, 255, 255, 0.2),
  0 0.4rem 0.6rem -0.2rem rgba(255, 255, 255, 0.15);
$boxShadow2: 0 1rem 1.4rem rgba(255, 255, 255, 0.06);
$boxShadow3: inset 3px 17px 37px rgba(3, 3, 3, 0.6),
  inset 0px 0px 5px rgba(255, 255, 255, 0.1);
$comman_bg:#1a1f23 ;
$table_head:#121517;
$table_bg:#1a1f23;