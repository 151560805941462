@import "./Asset/theme/_var.scss";
body{.container{
  max-width: 1476px;
}}


  .btnTransparent{
    background-color: transparent;
    box-shadow: unset;
    border: transparent;
  }

  ::-webkit-scrollbar {
    width: 0.2rem !important;
    background: rgba($secondary3, 0.075) !important;
  }
  
  ::-webkit-scrollbar-thumb {
    border-radius: 12px;
    background-color: rgba($secondary3, 0.3);
  }