@import "../../Asset/theme/_var.scss";



  .landingOuter {
    background-image: url("../../Asset/Img/btn_Ellipse.svg"),
      url("../../Asset/Img/middle_ellipse.png"),
      url("../../Asset/Img/right_bottom_ellipse.svg");
  
    background-repeat: no-repeat, no-repeat, no-repeat;
    background-position: top 0px left 0px, top 650px right 0, bottom 0 right 0;
    background-size: auto, auto, auto;
    .landing-page {
      .earnPassive {
        background-image: url("../../Asset/Img/tranparent_graphic.png");
        background-repeat: no-repeat, no-repeat;
        background-position: bottom right;
        background-size: contain;
        height: 917px;
        @media (max-width: 991px) {
          height: auto;
        }
        h2 {
          padding-top: 150px;
          font-weight: 800;
          font-size: 90px;
          color: $white;
          @media (max-width: 991px) {
            font-size: 40px;
            padding-top: 45px;
          }
        }
        p {
          max-width: 659px;
          margin: 50px 0 70px;
          font-size: 18px;
          font-weight: 500;
        }
      }
      .stakeCards-list {
        .stakeMain {
          @media (max-width: 991px) {
            justify-content: center;
          }
      
          .stakeCard-Outer {
            display: inline-flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            padding: 30px;
            border: 1px solid rgba(0, 0, 0, 0);
            border-radius: 20px;
            background: linear-gradient(136.03deg, rgba(0, 0, 0, 1) 1.93%, rgba(51, 50, 50, 0.001) 69.06%, rgba(0, 0, 0, 1) 93.41%) padding-box,
              linear-gradient(160deg, rgba(255, 255, 255, 0)10%, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0)) border-box;
            height: 100%;
            @media(max-width:767px){
              padding: 20px;
            }
            &:hover {
              translate: 0 -15px;
              transition: 0.3s;
            }
          }
        }
      }
  
      .accessibleFeatures,
      .stakeCard-Outer,
      .stakeCardblock_main_Outer,
      .ecosystem_main_list_Card {
        h4 {
          font-weight: 600;
          font-size: 50px;
          margin: 25px 0;
          color: $white;
          word-break: break-all;
          @media (max-width: 1299px) {
            font-size: 28px;
          }
          @media (max-width: 765px) {
            font-size: 26px;
            margin: 16px 0;
          }
        }
        p {
          color: $light_text;
          font-size: 18px;
          font-weight: 500;
          @media (max-width: 991px) {
            font-size: 16px;
          }
        }
      }
  
      .accessible-main {
        // margin: 120px 0;
        background-image: url("../../Asset/Img/wave_accessibility.svg");
        background-repeat: no-repeat;
        background-position: top left;
        background-size: auto;
        .accessibleOuter {
          @media (max-width: 991px) {
            justify-content: center;
            gap: 50px;
          }
          &_imgSec {
            img {
              max-width: 650px;
              width: 100%;
              @media (max-width: 991px) {
                margin: auto;
                display: block;
                max-width: 450px;
              }
            }
            @media (max-width: 991px) {
              text-align: center;
            }
          }
          &_contentSec {
            @media (max-width: 991px) {
              display: inline-flex;
              align-items: center;
              justify-content: center;
              width: 100%;
              flex-wrap: wrap;
              gap: 20px;
            }
            .accessibleFeatures {
              @media (max-width: 991px) {
                text-align: center;
                flex: 0 0 calc(50% - 20px);
                p {
                  max-width: 320px;
                  margin: auto;
                }
              }
              @media (max-width: 575px) {
                text-align: center;
                flex: 0 0 100%;
              }
            }
          }
        }
  
        h3 {
          font-size: 60px;
          font-weight: 800;
          padding-bottom: 60px;
        }
        .accessibleFeatures {
          margin-bottom: 70px;
        }
      }
      .stakeCardsBlock-list {
        .stakeCardblock_main {
          @media (max-width: 991px) {
            justify-content: center;
          }
          &_Outer {
            text-align: center;
            padding: 50px 60px;
            background: linear-gradient(
              88.08deg,
              rgba(1, 124, 201, 0.088) 3.13%,
              rgba(8, 147, 234, 0.2) 48.43%,
              rgba(1, 138, 224, 0.0991482) 98.38%
            );
           border: 1px solid rgba(255, 255, 255, 0.15);
            transition: 0.3s;
            margin: -5px; /* Ensure the element sits tightly in the parent */
            height: 100%;
            width: 100%;
            border-radius: 20px; /* Apply border-radius to the element */
            &:hover {
              translate: 0 -15px;
              transition: 0.3s;
            }
          }
        }
      }
  
      .ecosystem {
        // margin: 120px 0;
        background-image: url("../../Asset/Img/eco_Ellipse.svg");
        background-repeat: no-repeat;
        background-position: left top;
        background-size: contain;
        &_main {
          justify-content: space-between;
          h3.title {
            @media (max-width: 991px) {
              text-align: center;
            }
          }
          @media (max-width: 991px) {
            gap: 100px;
          }
  
          .subHeading + img {
            // background: rgba($color:$primary, $alpha: 0.1);
            backdrop-filter: blur(45px);
            max-width: 560px;
            width: 100%;
            @media (max-width: 991px) {
              margin: auto;
              display: block;
              max-width: 450px;
            }
          }
  
          &_list {
            @media (max-width: 991px) {
              display: inline-flex;
              align-items: center;
              justify-content: space-between;
              width: 100%;
              flex-wrap: wrap;
              gap: 20px;
            }
            &_Card {
              margin-bottom: 50px;
              @media (max-width: 991px) {
                text-align: center;
                flex: 0 0 calc(50% - 20px);
              }
              @media (max-width: 575px) {
                text-align: center;
                flex: 0 0 100%;
              }
            }
          }
        }
      }
  
      .backed-main {
        background-image: url("../../Asset/Img/back_by_bg.svg");
        background-repeat: no-repeat;
        background-position: top left -30px;
        background-size: auto;
        .backed-by {
          position: relative;
          z-index: 2;
          .subHeading {
            text-align: center;
            margin-bottom: 60px;
          }
          .backedCard {
            border-radius: 20px; /* Set the border radius */
            border: 1px solid rgba(255, 255, 255, 0.12) ;
            background-image: linear-gradient(
              126.03deg,
              rgba(255, 255, 255, 0) 1.93%,
              rgba(255, 255, 255, 0.12) 49.06%,
              rgba(255, 255, 255, 0) 93.41%
            );
            background-color: black;
            height: 100%;
            display: flex; /* Use flexbox for alignment */
            flex-direction: column; /* Stack children vertically */
            justify-content: center; /* Center items vertically */
            align-items: center; /* Center items horizontally */
            height: 180px;
            position: relative;
            @media(max-width:991px){
              height: 140px;
            }
            @media(max-width:575px){
              height: 120px;
            }
  
            img {
              max-width: 250px;
              width: 100%;
              @media(max-width:991px){
                max-width: 150px;
              }
              @media(max-width:575px){
                max-width: 120px;
              }
            }
            &:before {
              content: "";
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              z-index: -1;
              margin: -2px; /* !importanté */
              border-radius: inherit; /* inherit border-radius from parent */
              background: rgba(0, 0, 0, 0.7);
            }
       
          }
        }
      }
  
  

      .subHeading {
        h3.title {
          font-weight: 800;
          font-size: 60px;  /* 6rem = 60px */
          color: $white;
          margin-bottom: 30px; /* 3rem = 30px */
          @media (max-width: 1299px) {
            font-size: 40px;  /* 4rem = 40px */
          }
          @media (max-width: 991px) {
            font-size: 30px;  /* 3rem = 30px */
          }
        }
        p {
          font-size: 18px;  /* 1.8rem = 18px */
          font-weight: 500;
          color: $light_text;
        }
      }
      
      .space {
        margin: 120px 0;  /* 12rem = 120px */
        @media (max-width: 991px) {
          margin: 60px 0;  /* 6rem = 60px */
        }
      }
  }
  .landing_footer {
    background-image: url("../../Asset/Img/wave_bottom_right.svg");
    background-repeat: no-repeat;
    background-position: bottom 0 right 0;
    
    .list-container {
      display: flex; /* Aligns columns in a row */
      justify-content: space-between; /* Distributes space between columns */
      align-items: flex-start;
      padding: 20px; /* 2rem = 20px */
      gap: 40px; /* 4rem = 40px */
      padding: 120px 0 50px; /* 12rem = 120px, 5rem = 50px */
      border-top: 1px solid rgba(170, 170, 170, 0.3);
  
      @media (max-width: 991px) {
        flex-wrap: wrap;
        padding: 60px 0 30px; /* 6rem = 60px, 3rem = 30px */
      }
  
      @media (max-width: 767px) {
        gap: 30px; /* 3rem = 30px */
      }
  
      .list-column {
        flex: 0 0 33.3%;
        margin: 0 10px; /* 1rem = 10px */
  
        @media (max-width: 991px) {
          flex: 0 0 calc(50% - 50px);
        }
  
        @media (max-width: 767px) {
          flex: 0 0 100%;
        }
  
        h5 {
          margin-bottom: 10px; /* 1rem = 10px */
          font-size: 16px; /* 1.6rem = 16px */
          font-weight: 600;
          color: $white;
        }
  
        .links {
          display: inline-flex;
          gap: 50px; /* 5rem = 50px */
          justify-content: space-between;
          width: 100%;
  
          @media (max-width: 479px) {
            gap: 20px; /* 2rem = 20px */
          }
  
          span {
            align-items: left;
            flex-basis: 50%;
            margin: 5px 0; /* 0.5rem = 5px */
  
            a {
              font-size: 16px; /* 1.6rem = 16px */
              font-weight: 400;
              line-height: 30px; /* 3rem = 30px */
              text-decoration: none; /* Remove underline */
              color: $light_text; /* Link color */
              position: relative;
  
              @media(max-width:765px){
                line-height: 20px; /* 2rem = 20px */
              }
  
              &::before {
                position: absolute;
                content: "";
                width: 0%;
                height: 2px;
                background-color: $primary;
                display: block;
                margin: 10px 0; /* 1rem = 10px */
                transition: 0.5s;
                bottom: -14px; /* 1.4rem = 14px */
              }
  
              &:hover {
                color: $primary;
                cursor: pointer;
                &::before {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
  
    .center-content {
      justify-content: center; /* Center items in the column */
      align-items: flex-start; /* Center items horizontally */
      text-align: left; /* Center text within the column */
      margin-left: auto;
  
      address {
        max-width: 300px; /* 30rem = 300px */
        margin: 0 auto;
        color: $light_text;
  
        @media (max-width: 991px) {
          max-width: 100%;
        }
  
        p {
          margin-bottom: 10px; /* 1rem = 10px */
          font-size: 16px; /* 1.6rem = 16px */
          font-weight: 400;
        }
      }
    }
  
    .footerBar {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 40px; /* 4rem = 40px */
      gap: 50px; /* 5rem = 50px */
  
      @media (max-width: 991px) {
        gap: 30px; /* 3rem = 30px */
      }
  
      @media (max-width: 767px) {
        flex-wrap: wrap;
        flex-direction: column-reverse;
        margin-bottom: 20px; /* 2rem = 20px */
      }
  
      &_media {
        display: inline-flex;
        align-items: center;
        justify-content: flex-start;
        gap: 30px; /* 3rem = 30px */
  
        @media (max-width: 767px) {
          flex-wrap: wrap;
          justify-content: center;
          gap: 10px; /* rem = 10px */
        }
  
        a {
          img {
            @media (max-width: 479px) {
              width: 20px; /* 2rem = 20px */
              height: auto;
            }
  
            &:hover {
              filter: brightness(0) saturate(100%) invert(41%) sepia(97%) saturate(3625%) hue-rotate(193deg) brightness(101%) contrast(108%);
            }
          }
        }
      }
    }
  
    .heading {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      gap: 50px; /* 5rem = 50px */
  
      @media (max-width: 479px) {
        gap: 20px; /* 2rem = 20px */
      }
  
      h5 {
        flex-basis: 50%;
      }
    }
  }
  

}

.headingLink {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  gap: 30px;
}
