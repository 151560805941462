@import "../../Asset/theme/_var.scss";

.header {
  padding: 22px 0;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1001;
  transition: all linear 0.15s;
  // background-color: $black;
  border-bottom: 1px solid $borderClr;

  @media (max-width: 1679px) {
    padding: 15px 0;
  }
  @media (max-width: 767px) {
    padding: 10px 0;
  }
  &__scrolled {
    top: 0;
    padding: 18px 0;
    background-color: black;
    // box-shadow: $boxShadow2;

    @media (max-width: 1679px) {
      padding: 12px 0;
    }
    @media (max-width: 767px) {
      padding: 10px 0;
    }
  }

  .navbar {
    padding: 0;

    .navbar-nav {
      align-items: center;

      .nav-link {
        font-size: 16px;
        color: $white;
        font-weight: 500;
        line-height: 24px;
        text-transform: capitalize;
        cursor: pointer;
        transition: all linear 0.2s;
        border-bottom: 1px solid transparent;

        @media (max-width: 1679px) {
          font-size: 13px;
        }

        &.active {
          color: $primary;
          border-bottom-color: $primary;
        }
      }
    }

    .navbar-toggler {
      padding: 0;
      width: 32px;
      height: 32px;
      margin-right: -3px;

      svg {
        width: inherit;
        height: inherit;
      }
    }

    .custom-button {
      @media (max-width: 767px) {
        margin-left: 0;
        font-size: 12px;
        padding: 8px 10px;
      }

      &__icon {
        @media (max-width: 767px) {
          margin-left: 5px;
          top: 0;
        }
        svg {
          @media (max-width: 767px) {
            width: 18px;
          }
        }
      }
    }
  }

  .navbar-brand {
    padding: 0;
    @media (max-width: 1679px) {
      max-width: 230px;
    }

    @media (max-width: 1199px) {
      max-width: 220px;
    }

    @media (max-width: 767px) {
      max-width: 60px;
    }
  }

  &__action {
    display: flex;
    align-items: center;
    position: relative;
  }

  @media (max-width: 767px) {
    .mob-menu {
      background: rgba(0, 0, 0, 0.1);
      backdrop-filter: blur(10px);
      padding: 10px 20px;
      border-radius: 8px;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      display: none;

      .nav-link {
        font-size: 18px !important;
        margin-right: 0 !important;
      }

      .navbar-nav {
        display: flex;
        justify-content: center !important;
        align-items: center;
        gap: 10px;
        height: 100%;
      }

      &__close {
        top: 30px;
        right: 30px;
        position: absolute;
      }

      &.open {
        display: block;
        position: fixed;
        z-index: 11;
        right: 0;
        top: 0;
        left: 0;
        bottom: 0;
      }
    }
  }
}

.icon-btn {
  &:hover {
    .custom-button__icon {
      svg {
        path {
          fill: $primary;
        }
      }
    }
  }
}


