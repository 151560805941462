@font-face {
  font-family: "Century Gothic";
  src: url("CenturyGothic-Italic.woff2") format("woff2"),
    url("CenturyGothic-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Century Gothic";
  src: url("CenturyGothic-Bold.woff2") format("woff2"),
    url("CenturyGothic-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Century Gothic";
  src: url("CenturyGothic-BoldItalic.woff2") format("woff2"),
    url("CenturyGothic-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Century Gothic";
  src: url("CenturyGothic.woff2") format("woff2"),
    url("CenturyGothic.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Century Gothic";
  src: url("CenturyGothic-Bold.woff2") format("woff2"),
    url("CenturyGothic-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Century Gothic";
  src: url("CenturyGothic.woff2") format("woff2"),
    url("CenturyGothic.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Century Gothic";
  src: url("CenturyGothic.woff2") format("woff2"),
    url("CenturyGothic.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
